import React from "react";

import { Publisher }        from "../publisher"
import { kPageLoaded } from "../events";

interface State { 
  opacity:number,
  display:string
}

export class PreLoader extends React.Component {
  public constructor(props:any) {
    super(props)
    Publisher.instance().registerForEvent(kPageLoaded, (event:string) => {this.onPageLoaded(event)});
  }
  
  public readonly state: State = { 
    opacity:1,
    display:""
  }

  public render() {
    const divStyle = this.state;
    return (
      <div className="loader-wrapper" style={divStyle}>
        <div className='cssload-loader'>
          <div className='cssload-inner cssload-one'></div>
          <div className='cssload-inner cssload-two'></div>
          <div className='cssload-inner cssload-three'></div>
        </div>
      </div>
    )
  }

  private onPageLoaded(event:string) {
    this.fadeOut(50, 100);
  }

  private fadeOut(intervalMs:number, delay:number) {
    if(delay !== 0) {
      setTimeout(() => { 
        this.fadeOut(intervalMs, 0); 
      }, delay);
    } else { 
      setTimeout(() => {
        let opacity = this.state.opacity; 
        if(opacity > 0) {
          opacity = opacity -0.25;
          this.setState({opacity:opacity, display:""});
          this.fadeOut(intervalMs, 0);
        } else {
          this.setState({opacity:opacity, display:"none"});
        } 
      }, intervalMs);
    }
  }
}
