import React from "react";

import {onMouseHover} from "../util";

import GameDesignImg      from "../images/what-we-do/game-design.jpg";
import GameDesignImgHover from "../images/what-we-do/game-design-hover.jpg";
import MathImg            from "../images/what-we-do/math.jpg";
import MathImgHover       from "../images/what-we-do/math-hover.jpg";
import CreativeImg        from "../images/what-we-do/creative.jpg";
import CreativeImgHover   from "../images/what-we-do/creative-hover.jpg";
import DevImg             from "../images/what-we-do/dev.jpg";
import DevImgHover        from "../images/what-we-do/dev-hover.jpg";
import TestImg            from "../images/what-we-do/test.jpg";
import TestImgHover       from "../images/what-we-do/test-hover.jpg";
import DeployImg          from "../images/what-we-do/deploy.jpg";
import DeployImgHover     from "../images/what-we-do/deploy-hover.jpg";

import { EqualHeight, EqualHeightElement } from 'react-equal-height';

const kEntries:Map<string, any> = new Map([
  [ 
    "game-design",
    {
      "title":"GAME DESIGN",
      "text": `Whether you are looking for Slots, Scratchcards, Table Games or something totally different our\n
               expert Game Designers can help at any stage. you already have that spark of inspiration, or are\n
               looking for us to assist with full ideation and conceptualisation, we can provide that tiny spark\n
               and form it into a clear and concise game design specification regardless of the platform and\n
               production environment.`
    } 
  ],
  [
    "math",
    {
      "title":"MATH",
      "text":`Let’s face it, no-one really liked doing their math homework! Let our experienced game math team\n
              handle that for you. We've invested heavily in our personnel, and our industry-leading tools &\n
              technology to aid the simulation of games as early in the design process as possible. You're in safe\n
              hands, we've literally created 100s of unique and lucrative math models in our time for multiple\n
              customers, markets and game types.`
    }
  ],
  [
    "creative",
    {
      "title":"CREATIVE",
      "text":`Our professional team of highly talented and experienced 2D artists, Animators and Audio Designers\n
              can help bring any idea to life. If you need help with conceptualization or the entire asset\n
              production process we have the full service studio with the skills to take any concept through to\n
              completion. Whether that be character and symbol designs, logos, backgrounds, world building\n
              environments or full motion video graphics and production audio, all to very high standards.`
    }
  ],
  [
    "dev",
    {
      "title":"DEV",
      "text":`With hundreds of games under our belts we've never found a game our team can't handle. Whether its\n
              prototyping new features or producing full multi-platform projects our in-house Game Client and\n
              Server-side software specialists are ready to assist. Our proprietary development frameworks,\n
              extensive code-library of features and game logic engines as well as our highly experienced staff\n
              can have your games playable in a matter of days providing more time for game balancing and\n
              additional features.`
    }
  ],
  [
    "test",
    {
      "title":"TEST",
      "text":`A highly trained and versatile team with knowledge of all game types, quality standards and legal\n
              compliance requirements, our QA Team are there to ensure the highest standards of quality control.\n
              Not just an after-thought Quality Assurance is at the heart of everything we do throughout the game\n
              development life cycle, with the team guiding the games from development approval to final\n
              certification and post-launch support services.`
    }
  ],
  [
    "deploy",
    {
      "title":"DEPLOY",
      "text":`Unique to Games Inc.’s offering is our ability to add distribution to our mix of services. Not only\n
              are we able to provide bespoke software development services, but with our proprietary RGS Platform\n
              developed over a number of years and integrated with several industry-leading aggregation partners\n
              including Betsson Group, Relax Gaming, Games Global and Mr Slotty we’re able to assist you in\n
              accessing over 1000 operating brands worldwide through our network.`

    }
  ]
]);

const kImages: Map<string, string> = new Map([
  ["game-design",       GameDesignImg],
  ["game-design-hover", GameDesignImgHover],
  ["math",              MathImg],
  ["math-hover",        MathImgHover],
  ["creative",          CreativeImg],
  ["creative-hover",    CreativeImgHover],
  ["dev",               DevImg],
  ["dev-hover",         DevImgHover],
  ["test",              TestImg],
  ["test-hover",        TestImgHover],
  ["deploy",            DeployImg],
  ["deploy-hover",      DeployImgHover]
])

const entriesPerRow:number = 3

export class WhatWeDo extends React.Component {
  public render() {
    let entries:any[] = [];

    kEntries.forEach((value:any, key:string) => {
      //@ts-ignore
      entries.push(this.createEntry(key, value["title"], value["text"])); 
    });
    
    let rows:any[] = []; 

    for(let i = 0; i < (entries.length / entriesPerRow); ++i) {
      let entryStart = (i * entriesPerRow);
      let entryCount = ((i + 1) * entriesPerRow);
      rows.push(
        <div key={`wdw-container-${i}`} className="container">
          <div className="row">
            {entries.slice(entryStart, (entryCount < entries.length ? entryCount : entries.length))} 
          </div>
        </div>
      ); 
    }
    
    return (<EqualHeight><section className="blog-section ptb90">{rows}</section></EqualHeight>);
  }

  private createEntry(id:string, title:string, text:string, img:string) {
    return (
      <div key={`wdw-entry-${id}`} className="item col-lg-4 col-md-4 col-sm-12 col-sx-12">
      <div className="item-wrap">
        <div className="image" 
          onMouseOver={
            e => (onMouseHover(e, id, false, kImages))
          } 
          onMouseLeave={
            e => (onMouseHover(e, id, true, kImages))
          }
        >
        <img src={kImages.get(id)} alt="" className="img-responsive" />
        </div>
        <div className="item-info">
          <div className="item-header table fsize-14 fweight-700 uppercase">
          </div>
          <EqualHeightElement name = "wdwEntryTitle">
            <div className="item-title mt20" data-trim="40">{title}</div>
          </EqualHeightElement>
          <EqualHeightElement name = "wdwEntryTest">
            <div className="item-text mt20 lheight-100" data-trim="600">{text}</div>
          </EqualHeightElement>
        </div>
      </div>
     </div>
    );
  }
}
