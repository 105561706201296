import React from "react";

import { DAL      }  from "../model/dal";
import { Operator }  from "../model/operator"; 

import { Publisher }        from "../publisher";
import { kComponentLoaded } from "../events";

import SlottyImg    from "../images/operators/Mr Slotty.jpg"
import RelaxImg     from "../images/operators/Relax.jpg" 
import BetssonImg   from "../images/operators/Betsson.jpg" 
//import MGSImg       from "../images/operators/Microgaming.jpg" 
import GamesGlobalImg from "../images/operators/GamesGlobal.jpg" 

//TODO:: Later have this all DB driven from the entity service...
const kOperatorImages: Map<string, string> = new Map([ 
  ["Mr Slotty"    , SlottyImg        ],
  ["Betsson"      , BetssonImg       ],
  ["Relax"        , RelaxImg         ],
  ["Games Global" , GamesGlobalImg   ]
  //["Microgaming"  , MGSImg     ]
])

interface Props { }
interface State { operators:Operator[] }

export class Operators extends React.Component<Props, State> {
  public constructor(props: any) {
    super(props);
  }
  
  public readonly state: State = { operators: [] }
  
  public render() {
    return (
      <section className="pt90 pb170">
        <div className="container">
          <div className="row">
            <div className="title-bl text-center wow fadeIn" data-wow-duration="2s">
              <div className="title color-white">
                Our Customers:
              </div>
              <div className="subtitle">
                Customers
              </div>
            </div>
            <div className="testimonial-slider testimonial-tpl mt100">
              {this.state.operators.length > 0 && this.setOperators()}
            </div>
          </div>
        </div>
      </section>
    )
  }

  public componentDidMount() {
    DAL.instance().operators().then((operators: Operator[]) => {
      this.setState({operators:operators});
    })
  }
  
  private setOperators() {
    let html: any[] = []
    this.state.operators.forEach((operator:Operator) => {
      html.push((
        <div key={`operators-${operator.name}`} className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div className="slide-item">
              <div className="author-img">
                  <img src={kOperatorImages.get(operator.name)} alt="" />
              </div>
              <div className="text-center">
                  <i className="fa fa-quote-left" aria-hidden="true"></i>
                  <div className="fsize-24 fweight-700 color-white font-agency uppercase">
                    {operator.name}
                  </div>
                  <div className="color-1 fsize-14 fweight-700 uppercase">
                      RGS INTEGRATION PARTNERS
                  </div>
              </div>
          </div>
        </div>
      ));
    });
    Publisher.instance().publishEvent(kComponentLoaded);
    return html;
  } 
}
