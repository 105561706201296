import IDAL from "./idal"

import { Game }      from "./game";
import { Operator }  from "./operator"; 

import games      from "../games.json"
import operators  from "../operators.json"

export default class DiskDal extends IDAL {
  public constructor() {
    super();
  }

  public init(config:any) :void{}

  public async games():Promise<Game[]>          { return new Promise((resolve,reject)=>{resolve(games);}) }
  public async operators():Promise<Operator[]>  { return new Promise((resolve,reject)=>{resolve(operators);}) }

  public async getGame(code:string):Promise<Game> {
    return new Promise((resolve,reject) => {
      games.forEach((game: Game) => {
        if(code === game["code"]) {
          return resolve(game)
        }
      })
      reject();
    })
  }
  public async getOperator(id:string):Promise<Operator>{
    return new Promise((resolve, reject) => {
      operators.forEach((operator: Operator) => {
        if(id === operator["name"]) {
          return resolve(operator);
        }
      });
      reject();
    });
  }

}
