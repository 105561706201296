import React    from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

/*import {App}    from "./app";*/
/*
 * Components
 */
import { Footer           } from "./components/footer";
import { AgeVerification  } from "./components/age_verification";
import { Navi             } from "./components/navi";

/*
 * Pages
 */
import { ContactUs  } from "./pages/contact-us";
import { AboutUs    } from "./pages/about-us";
import { Games      } from "./pages/games";
import { GamePage   } from "./pages/game-page";
import { Home       } from "./pages/home";

/*
 * Dal
 */
import { DAL } from "./model/dal"

DAL.instance().init(undefined);
//DAL.instance().init("ws://18.134.246.186:12007");

ReactDOM.render(
  <Router>
    <AgeVerification />
    <Navi/>
    <Routes>
      <Route path="/"           element={<Home />} />
      <Route path="/games"      element={<Games />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/about-us"   element={<AboutUs />} />
      <Route path="/game-page"  element={<GamePage />} />
    </Routes>
    <Footer />
  </Router>,
  document.getElementById("root")
);


