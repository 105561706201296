import React from "react";

import { PreLoader }        from "../components/pre-loader";
import { Publisher }        from "../publisher";
import { kPageLoaded      } from "../events";

import { createPostLoadScripts }  from "../components/external-script"
import { toTop }                  from "../to-top"

export class ContactUs extends React.Component {
  public render() {
    return (
      <div>
        <PreLoader />
        <section className="page-name-contact-us parallax" data-paroller-factor="0.1" data-paroller-type="background" data-paroller-direction="vertical">
          <div className="container">
            <div className="row">
              <h1 className="page-title">
                Contact Us 
              </h1>
              <div className="breadcrumbs">
                <a href="/">Home</a> /
                <span className="color-1">Contact Us</span>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-page ptb150">
          <div className="container">
              <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <h3 className="">
                          Contact information
                      </h3>
                      <div className="fsize-18 lheight-30 mt40">
                        If you have any questions get in touch or send us a message! 
                      </div>
                      <div className="mt60">
                          <div className="contact-item table">
                              <div className="table-row">
                                  <div className="icon-bl table-cell valign-top">
                                      <i className="fa fa-phone fsize-28 fweight-400 color-1" aria-hidden="true"></i>
                                  </div>
                                  <div className="table-cell valign-top">
                                      <div>
                                          Phone Number
                                      </div>
                                      <div className="fsize-20 fweight-700 font-agency uppercase color-white">
                                        +44 (0)121 7790914
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="contact-item table">
                              <div className="table-row">
                                  <div className="icon-bl table-cell valign-top">
                                      <i className="fa fa-map-marker fsize-28 fweight-400 color-5" aria-hidden="true"></i>
                                  </div>
                                  <div className="table-cell valign-top">
                                      <div>
                                          Location Address
                                      </div>
                                      <div className="fsize-20 fweight-700 font-agency uppercase color-white">
                                        Games Inc.
                                        <br />
                                        TS2 Pinewood Business Park
                                        <br />
                                        Coleshill Road
                                        <br />
                                        Solihull
                                        <br />
                                        United Kingdom
                                        <br />
                                        B37 7HG
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="contact-item table">
                              <div className="table-row">
                                  <div className="icon-bl table-cell valign-top">
                                      <i className="fa fa-envelope fsize-28 fweight-400 color-6" aria-hidden="true"></i>
                                  </div>
                                  <div className="table-cell valign-top">
                                      <div>
                                          E-mail Address
                                      </div>
                                      <div className="fsize-20 fweight-700 font-agency uppercase color-white">
                                        enquires@gamesinc.co.uk
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <form action="#" className="contact-us-form mt100">
                          <div className="row">
                              <div className="input-wrap col-md-6 col-sm-6">
                                  <input type="text" className="general_input italic" placeholder="Full name" />
                              </div>
                              <div className="input-wrap col-md-6 col-sm-6">
                                  <input type="email" className="general_input italic" placeholder="Email address" />
                              </div>
                              <div className="input-wrap col-md-6 col-sm-6">
                                  <input type="email" className="general_input italic" placeholder="Phone Number" />
                              </div>
                              <div className="input-wrap col-md-6 col-sm-6">
                                  <input type="email" className="general_input italic" placeholder="Subject" />
                              </div>
                              <div className="input-wrap col-md-12">
                                  <textarea className="general_input italic" placeholder="Your Message..."></textarea>
                              </div>
                          </div>
                          <input type="submit" className="btn gradient mt30 color-white color-white plr50 ptb19" value="Send message" />
                      </form>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt100">
                    <div className="map">
                        <div id="map" className="height-600"></div>
                    </div>
                </div>
                </div>
          </div>
      </section>
      </div>
    );
  }
  public componentDidMount() {
    createPostLoadScripts([
      "./assets/js/script.js"
    ]);
    toTop();
    Publisher.instance().publishEvent(kPageLoaded);
  }
}
