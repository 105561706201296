import React, { CSSProperties } from "react";

import { Game }  from "../model/game"; 

/*
 * Assets
 */
import PlaceholderImg from "../images/games/placeholder-3.jpg"

interface Props { game:Game }
interface State { game:Game }

export class UpcomingGame extends React.Component<Props, State> {
  public readonly state: State = { game: this.props.game }

  public setGame(game:Game) {
    this.setState({game:game});
  }

  public render() {
    const entryHeight: CSSProperties = {
      height: "612px",
    };

    return (
      <div className="tab-content relative mt90">
        <div className="tab-pane fade active in text-left clearfix" id="tab-item-1">
          <div className="tab-info col-lg-6 col-md-12 col-sm-12 col-xs-12 ptb90 pl100" style={entryHeight}>
            <div className="uppercase fsize-32 fweight-700 font-agency color-white lheight-normal">
              {this.state.game.title}
            </div>
            <div className="mt50 lheight-26 fweight-300">
              {this.state.game.description}
            </div>
            <div className="mt60 table g-bottom">
              <div className="table-cell valign-middle">
                <a href={`/game-page?code=${this.state.game.code}`} className="btn gradient color-white plr60 ptb19">
                    Read more
                </a>
              </div>
            </div>
          </div>
          <div className="tab-img col-lg-6 col-md-12 col-sm-12 col-xs-12 pr0" style={entryHeight}>
            <div className="image-bl">
                <img className="game-full-img" src={`https://d1rzhjlrah66sm.cloudfront.net/${this.state.game.code}/game_logo_570x450.jpg`} alt="" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
