import { Game }      from "./game";
import { Operator }  from "./operator"; 

import IDAL from "./idal"


/*
 * NOTE WS Service used for future updates for real time game, news and operator update
 */

const kMs           = 10;
const kMsToWait     = 2500
const kMaxAttempts  = (kMsToWait / kMs)

const delay = async() => {
  return new Promise( resolve => setTimeout(resolve, kMs));  
};

const waitForData = async(dataReadyCheck: () => any[]) =>  {
  let counter: number = 0;
  while(counter < kMaxAttempts && dataReadyCheck().length === 0) {
    counter++;
    await delay();
  }
}

export default class EntityService extends IDAL {
  public constructor() {
    super();
    this.mUri           = "ws://127.0.0.1:12007";
    this.mGames         = [];
    this.mOperators     = [];
  }

  public init(config:any) : void {
    if(config !== undefined && config["uri"] !== undefined) {
      this.mUri = config["uri"];
    }
    if(this.mClient === undefined) {
      this.setupSocket();
    }
  }

  public async games():Promise<Game[]> { 
    await waitForData(() => {
      return this.mGames;
    }); 
    return new Promise((resolve, reject) => {
      resolve(this.mGames)
    });
  }

  public async operators():Promise<Operator[]> { 
    await waitForData(() => {
      return this.mOperators;
    }); 
    return new Promise((resolve, reject) => {
      resolve(this.mOperators)
    });
  }

  public async getGame(code:string):Promise<Game>{
    await waitForData(() => { return this.mGames; });
    return new Promise((resolve, reject) => {
      this.mGames.forEach((game: Game) => {
        if(code === game["code"]) {
          return resolve(game);
        }
      });
      return reject();
    });
  }

  public async getOperator(id:string):Promise<Operator>{
    await waitForData(() => { return this.mOperators; });
    return new Promise((resolve, reject) => {
      this.mOperators.forEach((operator: Operator) => {
        if(id === operator["name"]) {
          return resolve(operator);
        }
      });
      return reject();
    });
  }
  
  private setupSocket() {
    this.mClient = new WebSocket(this.mUri); 
    let parent = this;

    this.mClient.addEventListener("open", (e:Event) => {
      let storage = window.sessionStorage;
      if(storage.getItem("games") !== null) {
        //@ts-ignore
        this.mGames = JSON.parse(storage.getItem("games"))["games"]
      } else {
        console.log("Requesting data from DAL...");
        const gamesRequest = {
          "requestType":"getGames",
          "studio":"Games Inc"
        };
        //@ts-ignore
        parent.mClient.send(JSON.stringify(gamesRequest));
      } 

      if(storage.getItem("operators") !== null) {
        //@ts-ignore
        this.mOperators = JSON.parse(storage.getItem("operators"))["operators"]
      } else {
        const operatorsRequest = {
          "requestType":"getOperators"
        }
        //@ts-ignore
        parent.mClient.send(JSON.stringify(operatorsRequest)); 
      }
    });
    this.mClient.addEventListener("close", (e:Event) => {
      this.onSocketError(e);
    });
    this.mClient.addEventListener("error", (e:Event) => {
      this.onSocketError(e);
    });
    this.mClient.addEventListener("message", (e: MessageEvent) => {
      this.messageHandler(e.data);
    });
  }

  private messageHandler(msg:string) {
    let storage = window.sessionStorage;
    try {
      let msgAsJson = JSON.parse(msg);
      if("error" in msgAsJson) {
        console.log(`Socket Error ${msgAsJson["error"]}`);
      } else {
        let requestType = msgAsJson["requestType"];
        if(requestType === "getGames") {
          for (let g of msgAsJson["games"]) {
            if(!g["code"].includes("_de") && !g["code"].includes("_jmp")) {
              this.mGames.push(g);
            }
          }
          storage.setItem("games", JSON.stringify({"games":this.mGames}));
        } else if (requestType === "getOperators") {
          this.mOperators = msgAsJson["operators"];
          storage.setItem("operators", JSON.stringify({"operators":this.mOperators}));
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  private onSocketError(err: Event) {
    console.log(`Socket failure ${err}`);
    this.mClient = undefined;
  }

  private mUri:       string;
  private mClient:    WebSocket|undefined;
  private mGames:     Game[];
  private mOperators: Operator[];
}
