export const kPostLoadScripts = [
  "./assets/js/jquery-3.3.1.min.js",
  "./assets/plugins/slick-1.8.0/slick/slick.min.js",
  "./assets/plugins/flex-menu/flexmenu.min.js",
  "./assets/plugins/muuri-master/muuri.js",
  "./assets/plugins/vide-0.5.1/dist/jquery.vide.min.js",
  "./assets/plugins/paroller.js-master/dist/jquery.paroller.min.js",
  "./assets/js/jquery.inview.min.js",
  "./assets/js/progressbar.min.js",
  "./assets/plugins/lightbox2-master/dist/js/lightbox.js",
  "./assets/js/moment.min.js",
  "./assets/plugins/jquery-date-range-picker-master/dist/jquery.daterangepicker.min.js",
  "./assets/js/script.js",
  "./assets/plugins/wow-master/dist/wow.min.js"
]

export const appendExternalScript = (url:string) => {
  const script = document.createElement("script");
  script.src = url;
  document.head.appendChild(script);
}

export const createPostLoadScripts = (list?:string[]) => {
  let listToCreate:string[] = list ? list : kPostLoadScripts;
  listToCreate.forEach((script:string) => {
    appendExternalScript(script);
  });
}
