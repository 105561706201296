import React, { CSSProperties } from "react";

const slides:any[] = [
  {
    "title":"WELCOME TO THE ONE STOP SHOP FOR",
    "subTitle":"IGAMING EXCELLENCE",
    "text":"Whether you are an Operator looking for a partner for Bespoke Game Development Services, for the latest game from our extensive portfolio to release to your platform; or whether you are a Game Design studio looking for production assistance or world-class development expertise, GAMES INC are here for you.",
    "buttonText":"MORE ABOUT US",
    "uri":"/about-us"
  },
  {
    "title":"A SELECTION OF THE BEST",
    "subTitle":"LATEST GAMES",
    "text":"Click the link below to see more of our exciting available games portfolio!",
    "buttonText":"Our Games",
    "uri":"/games"
  },
  /*{
    "title":"TAKE A LOOK AT THE",
    "subTitle":"LATEST NEWS",
    "text":"Click the link below to see more of our exciting available games portfolio!",
    "buttonText":"UPCOMING GAMES/SEE NEWS",
    "uri":"/games"
  }*/
]

export class Slider extends React.Component {
  public render() {
    const n2_ss_4: CSSProperties = {
      fontSize: "16px",
    };
    const button_n2_ss: CSSProperties = {
      position: "absolute",
    }
    const ns_ss_4_spinner: CSSProperties = {
      display: "none"
    }
    const n2_ss_placeholder: CSSProperties = {
      position:"relative",
      zIndex:"2",
      color:"RGBA(0,0,0,0)",
      maxHeight:"3000px"
    }
    const n2_ss_placeholder_img: CSSProperties = {
      width:"100%",
      maxWidth:"3000px",
      display:"block"
    }
    let slidesHTML:any[] = [];
    let buttonsHTML:any[] = [];

    slides.forEach((item, index) => {
      slidesHTML.push(this.renderSlide(index, item.title, item.subTitle, item.text, item.buttonText, item.uri));
      buttonsHTML.push(
        <div className="n2-ow n2-style-2f5b33e7e6c0fd4c646594fe712ed299-dot n2-font-7b5c9760d048f32382e5cebedacdf830-dot " key={"slide-button-" + index} tabIndex={index}>{"0" + (index + 1)}
        </div>
      )
    });

    return (
      <section className="main-slider">
        <div id="n2-ss-4-align" className="n2-ss-align">
          <div className="n2-padding">
            <div id="n2-ss-4" className="n2-ss-slider n2-ow n2-has-hover n2notransition n2-ss-load-fade " style={n2_ss_4} data-creator="Smart Slider 3" data-minfontsizedesktopportrait="1" data-minfontsizedesktoplandscape="1" data-minfontsizetabletportrait="1" data-minfontsizetabletlandscape="1" data-minfontsizemobileportrait="1" data-minfontsizemobilelandscape="1" data-fontsize="16">
              <div className="n2-ss-slider-1 n2-ss-swipe-element n2-ow">
                <div className="n2-ss-slider-2 n2-ow">
                  <div className="n2-ss-slider-3 n2-ow">
                    <div className="n2-ss-slide-backgrounds"></div>
                    {slidesHTML}
                  </div>
                </div>
                <div data-ssleft="0+100" data-sstop="height/2-bulletheight/2" data-offset="100" className="n2-ss-widget n2-ss-widget-display-desktop n2-ss-widget-display-tablet n2-ss-widget-display-mobile  n2-flex n2-ss-control-bullet n2-ss-control-bullet-vertical" style={button_n2_ss}>
                  <div className="n2-style-3e702635329eba6b6b98a2cfe62c4664-simple  nextend-bullet-bar n2-ow n2-bar-justify-content-center">
                    {buttonsHTML}
                  </div>
                </div>
              </div>
            </div>
            <div className="n2-clear"></div>
            <div id="n2-ss-4-spinner" style={ns_ss_4_spinner}>
              <div>
                <div className="n2-ss-spinner-simple-white-container">
                  <div className="n2-ss-spinner-simple-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="n2-ss-4-placeholder" style={n2_ss_placeholder}>
          <img style={n2_ss_placeholder_img} className="n2-ow" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMCIgd2lkdGg9IjEyMDAiIGhlaWdodD0iNTAwIiA+PC9zdmc+" alt="Slider"/>
        </div>
        <div className="sl-social bg-white">
        </div>
      </section>
    );
  }

  private renderSlide(num:number, title:string, subTitle:string, text:string, buttonText:string, uri:string) {
    const bgSlideSrc = "./assets/plugins/slider/images/slide-" + (num + 1) + ".jpg";
    const n2_ss_layer_ow: CSSProperties = {
      overflow:"visible"
    };
    const n2_ss_main_content_ow: CSSProperties = {
      padding:"8.75em 0.625em 0.625em 0.625em"
    };
    const n2_ss_layer_main_content_ow: CSSProperties = {
      margin:"0em 0em 0em 0em",
      overflow:"visible"
    };
    const n2_ss_layer_main_content_ow2: CSSProperties = {
      margin:"2.5em 0em 0em 0em",
      maxWidth:"955px",
      overflow:"visible"
    };
    const n2_ss_layer_main_content_ow3: CSSProperties = {
      margin:"margin:3.125em 0em 0em 0em ;overflow:visible"
    };
    const n2_ss_4item: CSSProperties = {
      display:"block",
    };

    return (
      <div key={"slide-" + num} data-first={num + 1} data-slide-duration="0" data-id={15+num} className="n2-ss-slide n2-ss-canvas n2-ow  n2-ss-slide-15">
        <div className="n2-ss-slide-background n2-ow" data-mode="fill">
          <img data-hash="7f12344252192edee35072e997e65114" data-desktop={bgSlideSrc} data-blur="0" data-opacity="100" data-x="50" data-y="50" src={bgSlideSrc} alt=""/>
        </div>
        <div className="n2-ss-layers-container n2-ow" data-csstextalign="center">
          <div className="n2-ss-layer n2-ow" style={n2_ss_layer_ow} data-csstextalign="inherit" data-has-maxwidth="0" data-desktopportraitmaxwidth="0" data-cssselfalign="inherit" data-desktopportraitselfalign="inherit" data-pm="content" data-desktopportraitpadding="140|*|10|*|10|*|10|*|px+" data-desktopportraitinneralign="inherit" data-sstype="content" data-hasbackground="0" data-rotation="0" data-desktopportrait="1" data-desktoplandscape="1" data-tabletportrait="1" data-tabletlandscape="1" data-mobileportrait="1" data-mobilelandscape="1" data-adaptivefont="1" data-desktopportraitfontsize="100" data-plugin="rendered">
            <div className="n2-ss-section-main-content n2-ss-layer-content n2-ow" style={n2_ss_main_content_ow} data-verticalalign="center">
              <div className="n2-ss-layer n2-ow" style={n2_ss_layer_main_content_ow2} data-pm="normal" data-desktopportraitmargin="0|*|0|*|0|*|0|*|px+" data-desktopportraitheight="0" data-has-maxwidth="0" data-desktopportraitmaxwidth="0" data-cssselfalign="inherit" data-desktopportraitselfalign="inherit" data-sstype="layer" data-rotation="0" data-animations="eyJzcGVjaWFsWmVybyI6MCwiaW4iOlt7Im5hbWUiOiJcdTA0MTdcdTA0MzBcdTA0NDJcdTA0MzVcdTA0M2NcdTA0M2RcdTA0MzVcdTA0M2RcdTA0MzhcdTA0MzUgXHUwNDQxXHUwNDMyXHUwNDM1XHUwNDQwXHUwNDQ1XHUwNDQzIiwib3BhY2l0eSI6MCwieSI6NDAwfV19" data-desktopportrait="1" data-desktoplandscape="1" data-tabletportrait="1" data-tabletlandscape="1" data-mobileportrait="1" data-mobilelandscape="1" data-adaptivefont="0" data-desktopportraitfontsize="100" data-tabletportraitfontsize="80" data-mobileportraitfontsize="50" data-plugin="rendered">
                <div id="n2-ss-4item1" className="n2-font-be0a516f76efd05e36733289aaf2a6de-hover n2-ow"
                     style={n2_ss_4item}>{title}
                </div>
              </div>
              <div className="n2-ss-layer n2-ow" style={n2_ss_layer_main_content_ow} data-pm="normal" data-desktopportraitmargin="0|*|0|*|0|*|0|*|px+" data-desktopportraitheight="0" data-has-maxwidth="0" data-desktopportraitmaxwidth="0" data-cssselfalign="inherit" data-desktopportraitselfalign="inherit" data-sstype="layer" data-rotation="0" data-animations="eyJzcGVjaWFsWmVybyI6MCwiaW4iOlt7Im5hbWUiOiJcdTA0MTdcdTA0MzBcdTA0NDJcdTA0MzVcdTA0M2NcdTA0M2RcdTA0MzVcdTA0M2RcdTA0MzhcdTA0MzUgXHUwNDQxXHUwNDNiXHUwNDM1XHUwNDMyXHUwNDMwIiwiZGVsYXkiOjAuNSwib3BhY2l0eSI6MCwieCI6NDAwfV19" data-desktopportrait="1" data-desktoplandscape="1" data-tabletportrait="1" data-tabletlandscape="1" data-mobileportrait="1" data-mobilelandscape="1" data-adaptivefont="0" data-desktopportraitfontsize="100" data-tabletportraitfontsize="80" data-mobileportraitfontsize="50" data-plugin="rendered">
                <div id="n2-ss-4item2" className="n2-font-e5e826a3ba83ea69731d81df1c0fa84d-hover n2-style-01ddb7ca1f625e69d1a2381951aa5192-heading n2-ow"
                     style={n2_ss_4item}>{subTitle}
                </div>
              </div>
              <div className="n2-ss-layer n2-ow" style={n2_ss_layer_main_content_ow2} data-pm="normal" data-desktopportraitmargin="40|*|0|*|0|*|0|*|px+" data-desktopportraitheight="0" data-has-maxwidth="1" data-desktopportraitmaxwidth="955" data-cssselfalign="inherit" data-desktopportraitselfalign="inherit" data-sstype="layer" data-rotation="0" data-animations="eyJzcGVjaWFsWmVybyI6MCwiaW4iOlt7Im5hbWUiOiJCbHVyIiwiZGVsYXkiOjAuOCwib3BhY2l0eSI6MCwibjJibHVyIjoxMH1dfQ==" data-desktopportrait="1" data-desktoplandscape="1" data-tabletportrait="1" data-tabletlandscape="1" data-mobileportrait="1" data-mobilelandscape="1" data-adaptivefont="0" data-desktopportraitfontsize="100" data-tabletportraitfontsize="80" data-mobileportraitfontsize="90" data-plugin="rendered">
                <div className="n2-ow n2-ow-all n2-ss-desktop n2-ss-mobile n2-ss-tablet">
                  <p className="n2-font-7753338b51c1a1442e4b2825494fb15e-paragraph n2-ow">{text}</p>
                </div>
              </div>
              <div className="n2-ss-layer n2-ow" style={n2_ss_layer_main_content_ow3} data-pm="normal" data-desktopportraitmargin="50|*|0|*|0|*|0|*|px+" data-desktopportraitheight="0" data-has-maxwidth="0" data-desktopportraitmaxwidth="0" data-cssselfalign="inherit" data-desktopportraitselfalign="inherit" data-sstype="layer" data-rotation="0" data-animations="eyJzcGVjaWFsWmVybyI6MCwiaW4iOlt7Im5hbWUiOiJcdTA0MTdcdTA0MzBcdTA0NDJcdTA0NDNcdTA0NDVcdTA0MzBcdTA0M2RcdTA0MzhcdTA0MzUgXHUwNDQxXHUwNDNkXHUwNDM4XHUwNDM3XHUwNDQzIiwiZGVsYXkiOjEsIm9wYWNpdHkiOjAsInkiOi00MDB9XX0=" data-desktopportrait="1" data-desktoplandscape="1" data-tabletportrait="1" data-tabletlandscape="1" data-mobileportrait="1" data-mobilelandscape="1" data-adaptivefont="0" data-desktopportraitfontsize="100" data-tabletportraitfontsize="80" data-mobileportraitfontsize="100" data-plugin="rendered">
                <div className="n2-ss-button-container n2-ow n2-font-06be61400c1a4b339e67534a8a262348-link n2-ss-nowrap">
                  <a className="n2-style-588404275f372121297e39cfe0b03e24-heading n2-ow" href={uri}>
                    <div>{buttonText}</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
