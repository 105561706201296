import React from "react";

import { DAL  }  from "../model/dal";
import { Game }  from "../model/game"; 

import { Publisher }        from "../publisher";
import { kComponentLoaded } from "../events";

/*
 * Components
 */
import { UpcomingGame } from "./upcoming-game"

/*
 * Assets
 */
import PlaceholderImg from "../images/games/placeholder-2.jpg"

interface Props { }
interface State { games:any[] }

export class UpcomingGames extends React.Component<Props, State> {
  public constructor(props: any) {
    super(props);
    this.changeChild = React.createRef();
  }
  
  public readonly state: State = { games: [] }
  
  public render() {
    return (
      <section className="game-section pt150 pb85">
        <div className="container">
          <div className="row">
            <div className="title-bl text-center wow fadeIn" data-wow-duration="2s">
              <div className="title color-white">
                  UpComing Games:
              </div>
              <div className="subtitle">
                  Coming soon
              </div>
            </div>
            {this.state.games.length > 0 && this.createGames()}
          </div>
        </div>
      </section>
    );
  }

  public componentDidMount() {
    if(!this.state.games.length) {
      DAL.instance().games().then((games: Game[]) => {
        this.onGames(games);
      })
    }
  }

  private onGames(games: Game[]) {
    //Later this should be pulled from a release date algorithm, for now because we don't have that Mark has suggested a static set of games..
    const kGames:Map<string, boolean> = new Map([
      ["gi_001_mvs", false],
      ["gl_003_tpr", false],
      ["gi_009_cts", false],
      ["gi_012_tsrwb", false],
    ]);

    let _state = this.state;

    games.forEach((game:Game) => {
      if(kGames.has(game.code) && !kGames.get(game.code)) {
        kGames.set(game.code, true);
        _state.games.push(game);
      }
    });
    this.setState(_state);
    Publisher.instance().publishEvent(kComponentLoaded);
  }

  private createGames() {
    let html:any[] = [];
    
    let counter = 0;
    this.state.games.forEach((game:Game) => {
      let index = counter;
      html.push((
        <li key={`upcoming-games-${game.code}`} className="col-lg-3 col-md-3" onMouseOver={(e)=> {
          this.changeChild.current.setGame(this.state.games[index]);
        }}>
          <a href={`/game-page?code=${game.code}`} data-toggle="tab">
            <div className="img-wrap">
              <img src={`https://d1rzhjlrah66sm.cloudfront.net/${game.code}/game_logo_180x180.jpg`}/>
            </div>
            <div className="nav-header-bl mt25">
              <div className="nav-title color-white">
                {game.title}
              </div>
            </div>
          </a>
        </li>
      ));
      counter++;
    });
    
    return (
      <div className="tm-tabs tabs mt70">
        <div className="tabs-nav">
          <ul className="list-inline nav nav-tabs tabs-slider">
            {html}
          </ul>
        </div>
        <UpcomingGame game={this.state.games[0]} ref={this.changeChild}/>
      </div>
    )
  }
  private changeChild:  any; 
}
