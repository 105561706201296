import IDAL             from "./idal"
import DiskDAL          from "./disk-dal"
import EntityServiceDAL from "./entity-service-dal"

export class DAL {
  public static instance(): IDAL {
    if (!DAL.skInstance) {
      DAL.skInstance = new DAL();
    }
    return DAL.skInstance.mDAL;
  }

  private constructor() {
    this.mDals  = new Map();
    this.mDAL   = new DiskDAL(); 
    this.mDAL.init(undefined);

    this.mDals.set("default",       this.mDAL);
    this.mDals.set("disk",          this.mDAL);
    this.mDals.set("entityService", new EntityServiceDAL())
  }

  public setInterface(name:string, config:any) {
    if(this.mDals.has(name)){ 
      //@ts-ignore
      //We can guarantee due to above check.
      this.mDAL = this.mDals.get(name);
      this.mDAL.init(config);
    }
  }

  private static skInstance: DAL;
  private mDals: Map<String, IDAL>
  private mDAL: IDAL;
}

