import React, {MouseEvent, ReactElement} from "react";

import { EqualHeight, EqualHeightElement } from 'react-equal-height';

import { DAL  }  from "../model/dal";
import { Game }  from "../model/game"; 

import { Publisher }        from "../publisher";
import { kComponentLoaded } from "../events";

/*
 * Assets
 */
import PlaceholderImg from "../images/games/placeholder-1.jpg"

interface Props { }
interface State { 
  games:any[] 
  currentFilter:string
}

export class GameList extends React.Component<Props, State> {
  public constructor(props: any) {
    super(props);
  }
  
  public readonly state: State = { games: [], currentFilter:"all" }
  
  public render() {
    let html = [];

    return ((
      <div id="game-listing-component">
        <section className="game-list-types">
          <div className="container">
            <div className="row">
              {this.createFilters()}
            </div>
          </div>
        </section>
        <section className="ptb150">
          <div className="container">
            <div className="row">
              <div className="games-container">
                <div className="container">
                  <EqualHeight>
                    {this.createGameList()}
                  </EqualHeight>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    ));
  }

  public componentDidMount() {
    if(!this.state.games.length) {
      DAL.instance().games().then((games: Game[]) => {
        
        this.setState({games:games});
        Publisher.instance().publishEvent(kComponentLoaded, "game-list");
        //this.onGames(games);
      })
    }
  }

  private onFilter(type:string, e:MouseEvent) {
  }

  private createFilters() {
    let types:string[] = [];
    types.push("all");

    let html = [];
    for(let game of this.state.games) {
      let found = false;
      for (let t of types) {
        if (t === game.type) {
          found = true;
          break;
        }
      }
      if(!found) {
        types.push(game.type);
      }
    }

    for(let type of types) {
      let classname = ""
      if(this.state.currentFilter === type) {
        classname="color-1"
      }
      if(type !== "all") {
        classname += " span1"
      }
      html.push((
//        <span className={classname} key={`game-filter-${type}`} style={{cursor:"pointer"}} onClick={(e:MouseEvent<HTMLSpanElement>) => {this.setState({games:this.state.games, currentFilter:type});}}>&emsp;&emsp;&emsp;&emsp;{type}</span>
        <span className={classname} key={`game-filter-${type}`} style={{cursor:"pointer"}} onClick={(e:MouseEvent<HTMLSpanElement>) => {this.setState({games:this.state.games, currentFilter:type});}}>{type}</span>
      ));
    }
    return html;
  }

  private createGameList() {
    let _games: any[] = [];
    this.state.games.forEach((game: Game) => {
      if(this.state.currentFilter === "all" || this.state.currentFilter === game["type"]) {
        _games.push(this.createGame(game["code"], game["title"], (game["description"].substring(0, 91))));
      }
    });
    return _games;
  }

  private createGame(code:string, title:string, description:string) {
    return (
      <div key = {title} className="g-item col-lg-4 col-md-4 col-sm-6 col-xs-12 mb30 wow fadeInUp" data-wow-duration="1s">
        <div className="image-container">
          <img src={`https://d1rzhjlrah66sm.cloudfront.net/${code}/game_logo_370x270.jpg`}alt="" className="img-responsive" />
        </div>
        <div className="bottom-container">
          <div className="text-center mt20">
            <EqualHeightElement name = "gameEntryTitle">
              <a href={`/game-page?code=${code}`} className="g-name fsize-30 fweight-700 font-agency uppercase color-white">
                {title}
              </a>
            </EqualHeightElement>
            <EqualHeightElement name = "gameEntryDescription">
              <div className="mt20 color-2 lheight-26" data-trim="90">
                {description}
              </div>
            </EqualHeightElement>
          </div>
        </div>
      </div>
    )
  }
}
