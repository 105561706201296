import React from "react";

import logo from '../images/logo.png'

import { createNaviItems } from "./navi-items";

import {onMouseHover} from "../util";

import  GamblingComImg        from "../images/gambling_commision_logo.png";
import  GamblingComImgHover   from "../images/gambling_commision_logo_over.png";
import  MGAImg                from "../images/mga_logo.png";
import  MGAImgHover           from "../images/mga_logo_over.png";
import  BeGambleAwareImg      from "../images/begambleawareorg_logo.png";
import  BeGambleAwareImgHover from "../images/begambleawareorg_logo_over.png";

import  EighteenImg from "../images/18_logo.png";

const kRegulationImages: Map<string, string> = new Map([
  ["gambling-comission",        GamblingComImg        ],
  ["gambling-comission-hover",  GamblingComImgHover   ],  
  ["mga",                       MGAImg                ],
  ["mga-hover",                 MGAImgHover           ],  
  ["be-gamble-aware",           BeGambleAwareImg      ],
  ["be-gamble-aware-hover",     BeGambleAwareImgHover ]  
]);

const kRegulationBodies: Map<string, string> = new Map([
  ["gambling-comission","https://www.gamblingcommission.gov.uk/" ],
  ["mga","https://www.mga.org.mt/" ],
  ["be-gamble-aware","https://www.begambleaware.org/" ],
])

export class Footer extends React.Component {
  public render() {
    let regulatedImagesHtml:any[] = [];
     kRegulationBodies.forEach((value:string, key:string) => {
      if (!key.includes("hover")) {
        regulatedImagesHtml.push(this.createRegImage(key, value));
      }
    }); 

    return (
      <footer className="footer">
        <div className="container">
            <div className="row mb110">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <a href="/">
                        <img src={logo} alt="" />
                    </a>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 text-right">
                    <ul className="footer-menu">
                      {createNaviItems()}
                    </ul>
                    <div className="mt60">
                      Games Inc. is licensed and regulated by the Gambling Commission of Great Britain for Game Supply and B2B services under Licences: 000-041811-R-330799-001, and 000-041811-R-330799-001 and is recognised by the Malta Gaming Authority, our notice of recognition can be viewed <a href="https://www.mga.org.mt/recognition-notice/">here</a>.
                    </div>
                </div>
            </div>
            <div className="row">
              {regulatedImagesHtml}
              <img src={EighteenImg} alt="" />
            </div>
            <div className="row">
              {this.setCopyright()}
            </div>
        </div>
      </footer>
    )
  }

  private createRegImage(id: string, link:string) {
    return (
      <a key={`reg-image-${id}`} href={link} >
        <img className="reg-image" alt=""
          onMouseOver={ 
            e => (onMouseHover(e, id, false, kRegulationImages))
          }
          onMouseLeave={
            e => (onMouseHover(e, id, true, kRegulationImages))
          }
          src={kRegulationImages.get(id)} 
        >
        </img>
      </a>
    );
  }

  private setCopyright() {
    const currentYear = new Date().getFullYear();
    const copyRightString = `Copyright © ${currentYear}. Games Incorporated Limited.`;
    let html: any[] = [(
      <div key="copyright" className="copyright ptb30 col-lg-12">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          {copyRightString}
         </div>
         <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
             All Rights Reserved
         </div>
      </div>
    )]
    return html;
  }
}
