import React from "react";

import { PreLoader }              from "../components/pre-loader";
import { Slider }                 from "../components/slider";
import { WhatWeDo }               from "../components/what-we-do";
import { UpcomingGames }          from "../components/upcoming-games";
import { Operators }              from "../components/operators";
import { createPostLoadScripts }  from "../components/external-script"

import { kComponentLoaded } from "../events";
import { kPageLoaded }      from "../events";
import { Publisher }        from "../publisher"
import { toTop }            from "../to-top"


export class Home extends React.Component {
  public constructor(props:any) {
    super(props)
    const kComponentCount   = 2;
    let   componentsLoaded  = 0;
    Publisher.instance().registerForEvent(kComponentLoaded, (event:string, data?:any) => {
      componentsLoaded++; 
      if(componentsLoaded >= kComponentCount) {
        createPostLoadScripts([
          "./assets/js/script.js", 
        ]);
        toTop(); 
        Publisher.instance().publishEvent(kPageLoaded)
      }
    });
  }

  public render() {
    return (
      <div>
        <PreLoader />
        <Slider />
        <WhatWeDo />
        <UpcomingGames />
        <Operators />
      </div>
    );
  }
}
