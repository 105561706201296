import React from "react";

import logo from "../images/logo.png"

import { createNaviItems } from "./navi-items";

export class Navi extends React.Component {
  public render() {
    return (
      <div className="header-line-wrapper">
          <header className="header-wrapper fixed-top plr100">
              <div className="table height-100p">
                  <div className="table-row">
                      <div className="table-cell valign-middle text-left">
                          <a href="/" className="logo">
                              <img src={logo} alt="" className="img-responsive inline-block" />
                          </a>
                      </div>
                      <div className="table-cell valign-top text-center vm-sm">
                          <div className="main-menu">
                              <span className="toggle_menu">
                                  <span></span>
                              </span>
                              <ul className="menu clearfix">
                                {createNaviItems("inline-block")}
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </header>
      </div>
    );
  }
}
