import React from "react";

import { GameList }   from "../components/game-list";
import { PreLoader }  from "../components/pre-loader";

import { Publisher }        from "../publisher";
import { kComponentLoaded } from "../events";
import { kPageLoaded      } from "../events";

import { createPostLoadScripts } from "../components/external-script"
import { toTop }            from "../to-top"

export class Games extends React.Component {
  public constructor(props:any) {
    super(props)
    Publisher.instance().registerForEvent(kComponentLoaded, (event:string, data?:any) => {
      if(data === "game-list") {
        createPostLoadScripts([
          "./assets/js/script.js", 
        ]);
        toTop();
        Publisher.instance().publishEvent(kPageLoaded)
      }
    })
  }
  //public render() {
  //  console.log("Games page render being called");
  //  return (
  //    <div>
  //      <PreLoader />
  //      <section className="page-name parallax" data-paroller-factor="0.1" data-paroller-type="background" data-paroller-direction="vertical">
  //        <div className="container">
  //          <div className="row">
  //            <h1 className="page-title">
  //              Games
  //            </h1>
  //            <div className="breadcrumbs">
  //              <a href="/">Home</a> /
  //              <span className="color-1">Games</span>
  //            </div>
  //          </div>
  //        </div>
  //      </section>
  //      <section className="ptb150">
  //        <div className="container">
  //          <div className="row">
  //            <div className="games-container">
  //              <GameList />
  //            </div>
  //          </div>
  //        </div> 
  //      </section>
  //    </div>
  //  );
  //}
  public render() {
    console.log("Games page render being called");
    return (
      <div>
        <PreLoader />
        <section className="page-name parallax" data-paroller-factor="0.1" data-paroller-type="background" data-paroller-direction="vertical">
          <div className="container">
            <div className="row">
              <h1 className="page-title">
                Games
              </h1>
              <div className="breadcrumbs">
                <a href="/">Home</a> /
                <span className="color-1">Games</span>
              </div>
            </div>
          </div>
        </section>
        <GameList />
      </div>
    );
  }

}
