import React from "react";

import { PreLoader }  from "../components/pre-loader";
import { DAL }        from "../model/dal"
import { Game }       from "../model/game"

import { Publisher    } from "../publisher";
import { kPageLoaded  } from "../events";

import { createPostLoadScripts } from "../components/external-script";
import { toTop } from "../to-top"

import { isMobile } from "react-device-detect"

/*
 * Assets
 */
import PlaceholderImg from "../images/games/placeholder-1.jpg"

interface Props {}
interface State { 
  game:Game|null 
  title:string
}

export class GamePage extends React.Component<Props, State> {
  public render() {
    return (
      <div>
        <PreLoader />
        <section className="page-name parallax" data-paroller-factor="0.1" data-paroller-type="background" data-paroller-direction="vertical">
          <div className="container">
            <div className="row">
              <h1 className="page-title">
                {this.state.title}
              </h1>
              <div className="breadcrumbs">
                <a href="/">Home</a> /
                <a href="/games">Games</a> /
                <span className="color-1">{this.state.title}</span>
              </div>
            </div>
          </div>
        </section>
        {this.state.game && this.createGame()}
      </div>
    );
  }

  public readonly state: State = {
    game:null,
    title:""
  }

  public componentDidMount() {
    const queryString = window.location.search;
    console.log(queryString);
    const params      = new URLSearchParams(queryString); 
    let   gameCode    = params.get("code");
    console.log(gameCode);
    
    if(gameCode !== null) {
      DAL.instance().getGame(gameCode).then((g: Game) => {
        let state = this.state;
        state.game = g;
        state.title = g.title
        this.setState(state);
      }).catch(() => {
          console.log("Failed to load game");
          Publisher.instance().publishEvent(kPageLoaded);
          toTop();
          //REnder 404
      });
    }
  }

  private createGame() {
    const gameInfoEntry = (title:string, text:string) => {
      return (
        <div className="progress-line mb30">
          <div className="progress-title">
            {title} 
          </div>
          <svg viewBox="0 0 100 1" preserveAspectRatio="none">
            <path d="M 0,0.5 L 100,0.5" stroke="#1e1d32" strokeWidth="1" fillOpacity="0"></path>
          </svg>
          <div className="progressbar-text">
            {text}
          </div>
        </div>
      )
    }
    let gameHTML = (
      <section className="ptb150">
        <div className="container">
          <div className="row">
            <div className="top-bl">
              <div className="game-img-slider bg-arrow">
                <div className="image-wrapper">
                  <img src={
                    //@ts-ignore
                    `https://d1rzhjlrah66sm.cloudfront.net/${this.state.game.code}/screenshot_1_370x270.jpg`
                  }
                  alt="" className="img-responsive" />
                </div>
                <div className="image-wrapper">
                  <img src={
                    //@ts-ignore
                    `https://d1rzhjlrah66sm.cloudfront.net/${this.state.game.code}/screenshot_2_370x270.jpg`
                  }
                  alt="" className="img-responsive" />
                </div>
                <div className="image-wrapper">
                  <img src={
                    //@ts-ignore
                    `https://d1rzhjlrah66sm.cloudfront.net/${this.state.game.code}/screenshot_3_370x270.jpg`
                  }
                  alt="" className="img-responsive" />
                </div>
                <div className="image-wrapper">
                  <img src={
                    //@ts-ignore
                    `https://d1rzhjlrah66sm.cloudfront.net/${this.state.game.code}/screenshot_4_370x270.jpg`
                  }
                  alt="" className="img-responsive" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt80">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb30">
              <div className="uppercase fsize-32 fweight-700 font-agency color-white lheight-normal">
                {
                  //@ts-ignore
                  this.state.game.title
                } 
              </div>
              <div className="mt30 lheight-26 fweight-300">
                <p>
                  {
                    //@ts-ignore
                    this.state.game.description
                  }
                </p>
              </div>
              <input type="button" className="btn gradient color-white color-white plr50 ptb19" value="Play Game" onClick={() => {this.onPlay();}} />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb30">
              <div className="default-tabs">
                <div className="game-char">
                  {
                    //@ts-ignore
                    gameInfoEntry("CATEGORY",this.state.game.type)      
                  }
                  {
                    gameInfoEntry(
                      "VOLATILITY", 
                      //@ts-ignore
                      this.state.game.volatilityClassification !== undefined ? this.state.game.volatilityClassification : "N/A"
                    )                
                  }
                  {
                    //@ts-ignore
                    gameInfoEntry("RTP", this.state.game.drtp + "%")                   
                  }
                  {
                    gameInfoEntry(
                      "Pay Lines", 
                      //@ts-ignore
                      this.state.game.winlines !== undefined ? this.state.game.winlines : "N/A"
                    )      
                  }
                </div>
                </div>
              </div>
            </div>
          </div>
      </section>
    );
    createPostLoadScripts([
      "./assets/js/script.js", 
    ]);
    toTop();
    Publisher.instance().publishEvent(kPageLoaded);
    return gameHTML;
  }

  private onPlay() {
    let ts = Date.now()
    //@ts-ignore
    let url = `https://d1rzhjlrah66sm.cloudfront.net/${this.state.game.code}/?isFreeplay=true&vendor=default&lang=en&playToken=freeplay&operator=mock`;

    //@ts-ignore
    window.open(url, '_blank').focus();
    //if(!isMobile) {
    //  if(!this.iFrameLoaded) {
    //    this.iFrameLoaded = true;
    //    let parent = this;
    //    let iFrameDiv = document.createElement("div");
    //    iFrameDiv.id = "gameIFrame";
    //    let iFrame = document.createElement("iframe");
    //    //@ts-ignore
    //    iFrame.setAttribute("src", url);
    //    iFrame.setAttribute("width", "1280");
    //    iFrame.setAttribute("height", "720");
    //    //@ts-ignore
    //    iFrame.className = "gameIFrame";
    //    iFrameDiv.appendChild(iFrame);
    //    iFrameDiv.className = "gameIFrameDiv";
    //    let iFrameCloseBtn = document.createElement("input");
    //    iFrameCloseBtn.type="button";
    //    iFrameCloseBtn.className = "iFrameBtn btn gradient color-white color-white";
    //    iFrameCloseBtn.value = "X"
    //    iFrameCloseBtn.onclick = ((ev:MouseEvent) => {
    //      document.body.removeChild(iFrameDiv);
    //      parent.iFrameLoaded = false;
    //    })
    //    iFrameDiv.appendChild(iFrameCloseBtn);
    //    document.body.append(iFrameDiv);
    //  }
    //} else {
    ////@ts-ignore
    //  window.open(url, '_blank').focus();
    //}
  }

  private iFrameLoaded: boolean = false;
}
