import { Game }      from "./game";
import { Operator }  from "./operator"; 

export default abstract class IDAL {
  public abstract init(config:any): void;
  public abstract games():Promise<Game[]>;
  public abstract operators():Promise<Operator[]>;
  public abstract getGame(code:string):Promise<Game>;
  public abstract getOperator(id:string):Promise<Operator>;
}
