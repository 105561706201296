import React from "react";

import { PreLoader }        from "../components/pre-loader";
import { Publisher }        from "../publisher";
import { kPageLoaded      } from "../events";

import { createPostLoadScripts }  from "../components/external-script"
import { toTop }                  from "../to-top"
/*
 * Assets
 */
import Img from "../images/about-us.jpg"

export class AboutUs extends React.Component {
  public render() {
    return (
      <div>
        <PreLoader />
        <section className="page-name-about-us parallax" data-paroller-factor="0.1" data-paroller-type="background" data-paroller-direction="vertical">
            <div className="container">
                <div className="row">
                    <h1 className="page-title">
                      About Us
                    </h1>
                    <div className="breadcrumbs">
                        <a href="/">Home</a> /
                        <span className="color-1">About Us</span>
                    </div>
                </div>
            </div>
        </section>
      <section className="ptb150">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="item-wrapper-bl">
                        <div className="top-slider-bl bg-arrow">
                            <div>
                                <img src={Img} alt="" />
                            </div>
                        </div>
                        <div className="bottom-info-bl background-4 p60">
                            <div className="mt10">
                                <h4>About Us</h4>
                                <div className="mt30 lheight-26">
                                    <b> Games Inc. is an independent Game & Systems Software Developer focused exclusively on the supply of content & services to the global Online Real-Money Casino Market. </b>
                                    <br/><br/>
                                    <p> Whether you are an Operator looking for a partner for Bespoke Game Development Services, for the latest game from our extensive portfolio to release to your platform; or whether you are a Game Design studio looking for production assistance or world-class development expertise, GAMES INC are here for you. </p>
                                    <p> Our Established UK-based Game Studio can provide professional, experienced and cost-effective services to aid your game production needs whether you are looking for the full one-stop service or simply for additional capacity in any of the above disciplines. </p>
                                    <p> We're a rapidly expanding team of award-winning specialist Game Designers & Developers, Artists, Animators, Client/App Developers, Server-side Engineers & Product Support Staff based in the Heart of England, with over 100 years combined experience within the wider Gaming Industry, and the Online Sector in particular, dating all the way back to 1994 and 2003 respectively. </p>
                                    <p> We've been lucky enough to have worked behind the scenes with many Manufacturers, Platforms and Tier One Operators directly, and have had the pleasure to develop some of the industry's leading game licenses, IP and brands for those partners. </p>
                                    <p> We've developed an extensive library of high-quality content across multiple genres. With our proprietary technologies, modular code library built over a number of years, our multi-channel compatibility, and our signficant investment in development and deployment frameworks, Games Inc is able to offer you a Full-Service solution. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
      </div>
    );
  }

  public componentDidMount() {
    createPostLoadScripts([
      "./assets/js/script.js", 
    ]);
    toTop();
    Publisher.instance().publishEvent(kPageLoaded);
  }
}
