const kEntries:any[] =[
  {
    "name":"Homepage",
    "uri":"/"
  },
  {
    "name":"Games",
    "uri":"/games"
  },
  {
    "name":"About Us",
    "uri":"/about-us"
  },
  {
    "name":"Contact Us",
    "uri":"/contact-us"
  }
]

export const createNaviItems = (liClassname?: string): any[] => {
  const path                = window.location.pathname; 
  const entriesHTML: any[]  = []
  const className           = (liClassname === undefined ? "" : liClassname);

  kEntries.forEach((value: any) => {
    let onPage: boolean = (value["uri"] === path);
    entriesHTML.push(
      <li key={`navi-${value["uri"]}`} className={onPage ? (className + " active") : className}>
        <a href={value["uri"]}>
          {value["name"]}
        </a>
      </li>
    )
  })

  return entriesHTML;
}
