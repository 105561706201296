export const toTop =(): void => {
  let toTop = document.createElement("a"); 
  toTop.id = "toTop"
  toTop.className="color-white"
  
  let toTopI = document.createElement("i");
  toTopI.className="fa fa-angle-up fsize-14";
  toTop.appendChild(toTopI);
  document.body.appendChild(toTop);
};
