import React from "react";

interface State { verified:boolean }

export class AgeVerification extends React.Component {
  public readonly state: State = { verified : false }
  public constructor(props:any) {
    super(props)

    if(window.sessionStorage.getItem("verified") !== null) {
      this.state.verified = window.sessionStorage.getItem("verified") === "1"
    }
  }
  public render() {
    return (
      null  
    );
  }

  public componentDidMount() {
    let parent = this;
    if (!this.state.verified) {
      //@ts-ignore
      document.getElementById("ageVerification").style.display = "block";
      //@ts-ignore
      document.getElementById("root").style.pointerEvents = "none";
      //@ts-ignore
      document.getElementById("root").style.opacity = 0.1;
      document.addEventListener("wheel", this.preventDefault, {
        passive:false,
      })
      //@ts-ignore
      document.getElementById("ageVeriButtonYes").onclick = ((ev:MouseEvent) => {
        parent.onYes();
      });
      //@ts-ignore
      document.getElementById("ageVeriButtonNo").onclick = ((ev: MouseEvent) => {
        parent.onNo();
      })
    } else {
      this.remove();
    }
    
  }
  private preventDefault(e: any) {
    e = e || window.event
    if (e.preventDefault) {
      e.preventDefault()
    }
    e.returnValue = false
  }

  private onYes() {
    this.state.verified = true;
    //@ts-ignore
    document.getElementById("root").style.pointerEvents = "auto";
    document.removeEventListener("wheel", this.preventDefault, false)
    //@ts-ignore
    document.getElementById("root").style.opacity = 1.0;
    window.sessionStorage.setItem("verified", "1")

    this.remove();
  }
  private onNo() {
    this.state.verified = false;
    window.sessionStorage.setItem("verified", "0")
    if (!!navigator.userAgent.match(/iPhone|iPod|iPad/)) {
      window.location.replace("https://google.com"); 
    } else {
      window.history.back();
    }
  }
  private remove() {
    //@ts-ignore
    document.getElementById("ageVerification").remove();
  }
}
