export type EventHandler = (event:string, data?:any|null) => void;

export class Publisher {
  private constructor() {
    this.mHandlers = new Map<string, any>(); 
  }
  
  public static instance():Publisher {
    if(!Publisher.gInstance) {
      Publisher.gInstance = new Publisher(); 
    } 
    return Publisher.gInstance;
  }

  public registerForEvent(event:string, eventHandler:EventHandler) {
    if(!this.mHandlers.has(event)) {
      this.mHandlers.set(event, []);
    } 
    //Lint error not possible due to above.
    //@ts-ignore
    this.mHandlers.get(event).push(eventHandler);
  }

  public publishEvent(event:string, data?:any|null) {
    this.mHandlers.forEach((handlers:EventHandler[], key:string) => {
      if(key === event) {
        handlers.forEach((handler:EventHandler) => {
          handler(event, data);
        })
      }
    })
  }
  
  private mHandlers:Map<string, EventHandler[]>;
  private static gInstance:Publisher;
};
